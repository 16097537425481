import "./Card.scss";

import React from "react";
import { motion } from "framer-motion";

function Card({ title, text, image, seperator, index }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, delay: 0.3 * index }}
      viewport={{ once: true }}
      className="card"
    >
      <div className="card-title">{title}</div>
      <div className="card-text">{text}</div>
      <div className="card-image">
        <img src={image} alt="Icons8" />
      </div>
      {seperator ? <hr className="card-seperator" /> : ""}
    </motion.div>
  );
}

export default Card;
