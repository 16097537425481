import "./Header.scss";

import { ReactComponent as Logo } from "../../assets/images/logoFullWhite.svg";
import { ReactComponent as Burger } from "../../assets/images/burger.svg";

import { motion } from "framer-motion";
import React from "react";

function Header({ handleMenu }) {
  return (
    <div className="header">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.4, delay: 1 }}
        viewport={{ once: true }}
        className="header-logo"
      >
        <Logo />
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.4, delay: 1.2 }}
        viewport={{ once: true }}
        className="header-burger"
        onClick={handleMenu}
      >
        <Burger />
      </motion.div>
    </div>
  );
}

export default Header;
