import "./Experience.scss";

import React from "react";
import { motion } from "framer-motion";

import education1 from "../../assets/images/education1.jpg";
import personal from "../../assets/images/personal.jpg";
import travel from "../../assets/images/travel.jpg";
import cafe from "../../assets/images/cafe.jpg";
import StackCard from "../../components/StackCard/StackCard";

function Experience() {
  const industries = [
    {
      id: 1,
      name: "Education",
      description:
        "We have developed comprehensive solutions for educational institutions, including ERP systems that streamline administrative tasks like student management, fee collection, attendance tracking, and examination scheduling. Our solutions are designed to enhance efficiency and improve operational workflows.",
      projectHighlight:
        "University/College & School ERP Systems. Management websites and apps",
      img: education1,
      color: "rgb(237, 217, 255)",
    },
    {
      id: 2,
      name: "Travel & Tourism",
      description:
        "Our work in the travel and tourism industry has involved building websites for travel agencies to promote their services, showcase trip listings, and integrate booking and payment systems. These platforms deliver a seamless user experience while enhancing business visibility.",
      projectHighlight: "Travel Agency Management System. Tourism websites.",
      img: travel,
      color: "rgb(255, 244, 217)",
    },
    {
      id: 3,
      name: "Hospitality & Food Services",
      description:
        "We’ve crafted tailored solutions for restaurants and cafes, including websites that feature menus, online reservations, and delivery options. Our designs reflect the unique identity of each establishment, helping them connect with customers and grow their digital presence.",
      projectHighlight: "Restaurant/Cafe Websites & Management Platform",
      img: cafe,
      color: "rgb(237, 217, 255)",
    },
    {
      id: 4,
      name: "Personal & Professional Branding",
      description:
        "In the realm of personal and professional branding, we develop custom websites that highlight individual talents, services, and portfolios. These websites help professionals and entrepreneurs establish a strong online presence and connect with their target audience.",
      projectHighlight: "Personal/Professional Brand Websites",
      img: personal,
      color: "rgb(232, 249, 221)",
    },
  ];
  return (
    // <div
    //   className="experience"
    //   id="Company"
    //   style={{ backgroundImage: `url(${experienceBg})` }}
    // >
    <div className="experience" id="Company">
      {/* <div className="experience-left">
        <motion.div
          initial={{ opacity: 0, x: -150 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="experience-left-image"
        >
          <img src={img1} alt="Build websites in Pune" />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: -150 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="experience-left-text"
        >
          <div className="experience-left-text-number">5+</div>
          <div className="experience-left-text-name">Clients</div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 150 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="experience-left-text"
        >
          <div className="experience-left-text-number">15+</div>
          <div className="experience-left-text-name">Technologies</div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: 150 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="experience-left-image"
        >
          <img src={img2} alt="Build mobile app in Pune" />
        </motion.div>
      </div> */}
      <div className="experience-top">
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="experience-top-title"
        >
          {/* Innovating in record time. */}
          Delivering Excellence Across Industries
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          viewport={{ once: true }}
          className="experience-top-text"
        >
          With a deep understanding of industry-specific challenges, we’ve
          partnered with businesses across education, travel, hospitality, and
          more. Our innovative solutions are tailored to meet the unique needs
          of each sector, driving operational efficiency and growth for our
          clients.
          {/* Among millions of websites and apps, we craft ours to make them stand
          out. We build websites and applications that are fast in performance,
          cool to look at and incredible to own. */}
        </motion.div>
      </div>
      <div className="experience-cards">
        {industries.map((item, index) => (
          <StackCard key={item.id} index={index} color={item.color}>
            <div className="scard-content">
              <h2 className="scard-content-title">{item.name}</h2>
              <hr className="scard-content-sep" />
              <div className="scard-content-desc">{item.description}</div>
              <h3 className="scard-content-project">{item.projectHighlight}</h3>
            </div>

            <div className="scard-image">
              <img src={item.img} alt="" />
            </div>
          </StackCard>
        ))}
      </div>
    </div>
  );
}

export default Experience;
