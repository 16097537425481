import "./Footer.scss";

import React from "react";

import { Link } from "react-router-dom";

import logoIcon from "../../assets/images/logoIcon.svg";
import linkedin from "../../assets/images/linkedIn.png";
import instagram from "../../assets/images/instagram.png";
import email from "../../assets/images/email.png";
import phone from "../../assets/images/phone.png";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-left">
        <img
          className="footer-left-image"
          src={logoIcon}
          alt="Lancehawks Logo"
        />
        <div className="footer-left-name">Lancehawks</div>
      </div>

      <div className="footer-seperator"></div>

      <div className="footer-middle">
        <div className="footer-middle-heading">Follow us on</div>
        <div className="footer-middle-links">
          <Link
            target="_blank"
            to={`https://www.linkedin.com/company/lancehawks/`}
            className="footer-middle-links-link"
          >
            <img src={linkedin} alt="Lanchawks Linkedin" />
            <span>/Lancehawks</span>
          </Link>
          <Link
            target="_blank"
            to={`https://www.instagram.com/lancehawks.labs/`}
            className="footer-middle-links-link"
          >
            <img src={instagram} alt="Lanchawks Instagram" />
            <span>/Lancehawks.Labs</span>
          </Link>
        </div>
      </div>

      <div className="footer-seperator"></div>

      <div className="footer-right">
        <div className="footer-right-heading">Contact us at</div>
        <div className="footer-right-links">
          <Link
            target="_blank"
            to={`mailto:contact@lancehawks.com`}
            className="footer-right-links-link"
          >
            <img src={email} alt="Lanchawks Linkedin" />
            <span>contact@lancehawks.com</span>
          </Link>
          <Link
            target="_blank"
            to={`tel:+919915697333`}
            className="footer-right-links-link"
          >
            <img src={phone} alt="Lanchawks Instagram" />
            <span>+91 991 569 7333</span>
          </Link>
        </div>
      </div>
      <div className="footer-footer">
        <div className="footer-footer-copyright">
          &#169; Copyright 2024 Lancehawks.
        </div>
        <div className="footer-footer-desc">
          Designed and Developed by{" "}
          <Link target="_blank" to={`https://siddharthrai.com/`}>
            {" "}
            Siddharth Rai
          </Link>
          .
        </div>
      </div>
    </div>
  );
}

export default Footer;
