import "./Loader.scss";
import { ReactComponent as Logo } from "../../assets/images/lancehawks.svg";

import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

const elements = ["L", "A", "N", "C", "E", "H", "A", "W", "K", "S"];

const { innerHeight: height } = window;
const loaderVariant = {
  initial: { x: 1000, scaleX: 0 },
  animate: { x: 0, scaleX: 1, transition: { duration: 0.4 } },
  exit: { y: -height, transition: { duration: 0.4 } },
};

function Loader() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2800);
  }, []);

  return (
    <AnimatePresence>
      {isLoading ? (
        <motion.div
          className="loader"
          key="loader"
          variants={loaderVariant}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <motion.div
            className="loader-logo"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 2 }}
          >
            <Logo />
          </motion.div>
          <div className="loader-text">
            {elements.map((ele, index) => (
              <motion.span
                key={index}
                className="loader-text-letter"
                initial={{ x: 2000 }}
                animate={{ x: 0 }}
                transition={{ delay: 0.5 + 0.1 * index }}
              >
                {ele}
              </motion.span>
            ))}
          </div>
        </motion.div>
      ) : (
        ""
      )}
    </AnimatePresence>
  );
}

export default Loader;
