import Footer from "../../components/Footer/Footer";
import Loader from "../../components/loader/Loader";
import Contact from "../../sections/Contact/Contact";
import Experience from "../../sections/Experience/Experience";
import Hero from "../../sections/Hero/Hero";
import Services from "../../sections/Services/Services";
import WhoWeAre from "../../sections/WhoWeAre/WhoWeAre";
import "./Homepage.scss";

import React, { useEffect, useState } from "react";

function Homepage() {
  const [hash, setHash] = useState(null);

  useEffect(() => {
    //const hashkey = window.location.hash.slice(1); // Remove the '#' character from the hash
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  return (
    <div className="homepage">
      <Loader />
      <Hero setHash={setHash} />
      <WhoWeAre />
      <Services />
      <Experience />
      <Contact />
      <Footer />
    </div>
  );
}

export default Homepage;
