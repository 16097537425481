import "./Heading.scss";

import { motion } from "framer-motion";
import React from "react";

function Heading({ backtext, heading, color }) {
  return (
    <div className="heading">
      <motion.div
        initial={{ opacity: 0, x: -200 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.4 }}
        viewport={{ once: true }}
        className={`heading-backtext heading-backtext-${color}`}
      >
        {backtext}
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: -200 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.4, delay: 0.3 }}
        viewport={{ once: true }}
        className={`heading-maintext heading-maintext-${color}`}
      >
        {heading}
      </motion.div>
    </div>
  );
}

export default Heading;
