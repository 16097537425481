import "./Services.scss";

import { motion } from "framer-motion";
import React from "react";

import innovate1 from "../../assets/images/innovate1.jpg";
import innovate2 from "../../assets/images/innovate2.jpg";
import transform1 from "../../assets/images/transform1.jpg";
import transform2 from "../../assets/images/transform2.jpg";
import Tag from "../../components/Tag/Tag";
import Heading from "../../components/Heading/Heading";

const innovationtags = [
  "UI/UX Design",
  "Website Development",
  "App Design",
  "App Development",
];

const transformtags = [
  "Redesign",
  "Website Revamp",
  "Inhance Performace",
  "Add Features",
];

function Services() {
  return (
    <div className="services" id="Services">
      <div className="services-decorators decorator1"></div>
      <div className="services-decorators decorator2"></div>
      <div className="services-decorators decorator3"></div>
      <div className="services-decorators decorator4"></div>
      <Heading backtext="Our services" heading="Our Services" color="black" />
      <div className="services-first">
        <div className="services-first-border"></div>
        <div div className="services-first-left">
          <motion.img
            initial={{ opacity: 0, x: -100, y: 50 }}
            whileInView={{ opacity: 1, x: 0, y: 50 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="services-first-left-image2"
            src={innovate2}
            alt="Digital Innovation at Lancehawks"
          />
          <motion.img
            initial={{ opacity: 0, x: -100, y: -50 }}
            whileInView={{ opacity: 1, x: 0, y: -50 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="services-first-left-image1"
            src={innovate1}
            alt="Build world class websites"
          />
        </div>
        <div className="services-first-right">
          <motion.div
            initial={{ opacity: 0, x: -60, y: 150 }}
            whileInView={{ opacity: 1, x: 0, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="services-first-right-title"
          >
            Digital Innovation
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -60, y: 150 }}
            whileInView={{ opacity: 1, x: 0, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            viewport={{ once: true }}
            className="services-first-right-text"
          >
            Worried about the digital presence of your business? Need a website
            or app to take your business to the next level? Leave it to us, just
            tell us your requirements and we will take care of the rest.
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -60, y: 50 }}
            whileInView={{ opacity: 1, x: 0, y: 0 }}
            transition={{ duration: 0.3, delay: 0.6 }}
            viewport={{ once: true }}
            className="services-first-right-tags"
          >
            {innovationtags.map((tag, index) => (
              <Tag key={index} text={tag} />
            ))}
          </motion.div>
        </div>
      </div>

      <div className="services-second">
        <div className="services-second-border service-border"></div>

        <div className="services-second-left">
          <motion.div
            initial={{ opacity: 0, x: 60, y: 150 }}
            whileInView={{ opacity: 1, x: 0, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="services-second-left-title"
          >
            Digital Transformation
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 60, y: 150 }}
            whileInView={{ opacity: 1, x: 0, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            viewport={{ once: true }}
            className="services-second-left-text"
          >
            Need to revamp your websites or app? Need better performance, better
            designs? Or in other words - want to become a cool business? Sure,
            let’s work together on it.
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 60, y: 50 }}
            whileInView={{ opacity: 1, x: 0, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            viewport={{ once: true }}
            className="services-second-left-tags"
          >
            {transformtags.map((tag, index) => (
              <Tag key={index} text={tag} />
            ))}
          </motion.div>
        </div>

        <div className="services-second-right">
          <motion.img
            // initial={{ scale: 0.8, x: 60, y: 150 }}
            // whileInView={{ scale: 1, x: 0, y: 25 }}
            initial={{ opacity: 0, x: 100, y: 50 }}
            whileInView={{ opacity: 1, x: 0, y: 25 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="services-second-right-image2"
            src={transform2}
            alt="Digital Innovation at Lancehawks"
          />
          <motion.img
            // initial={{ scale: 0.8, x: 60, y: 150 }}
            // whileInView={{ scale: 1, x: 0, y: -50 }}
            initial={{ opacity: 0, x: 50, y: 100 }}
            whileInView={{ opacity: 1, x: 0, y: -50 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="services-second-right-image1"
            src={transform1}
            alt="Build world class websites"
          />
        </div>
      </div>

      <div className="services-third">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4 }}
          viewport={{ once: true }}
          className="services-third-title"
        >
          Custom Technology Solutions
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4 }}
          viewport={{ once: true }}
          className="services-third-text"
        >
          We love solving business problems using technology. Diving into
          problems and using our tech expertise to emerge with incredible
          solutions is something that we are passionate about. If there is a
          problem that technology can solve, we can help.
        </motion.div>
      </div>
    </div>
  );
}

export default Services;
