import Card from "../../components/Card/Card";
import "./WhoWeAre.scss";

import React from "react";

import imagine from "../../assets/images/imagine.png";
import build from "../../assets/images/build.png";
import transform from "../../assets/images/transform.png";
import hawkbg from "../../assets/images/hawkbg.png";
import Heading from "../../components/Heading/Heading";

const data = [
  {
    title: "We Imagine",
    text: "We imagine futuristic technology solutions for emerging and well established businesses.",
    image: imagine,
    seperator: true,
  },
  {
    title: "We Build",
    text: "We build platforms and digital products that perform, are well designed and feel great to use.",
    image: build,
    seperator: true,
  },
  {
    title: "We Transform",
    text: "We turn boring to interesting, old to new and dull to performant. We transform businesses digitally.",
    image: transform,
    seperator: false,
  },
];

function WhoWeAre() {
  return (
    <div
      id="About"
      className="who"
      style={{ backgroundImage: `url(${hawkbg})` }}
    >
      <Heading backtext="Lancehawks" heading="We're Lancehawks" color="white" />
      <div className="who-cards">
        {data.map((item, index) => (
          <Card
            key={index}
            title={item.title}
            text={item.text}
            image={item.image}
            seperator={item.seperator}
            index={index}
          />
        ))}
      </div>
    </div>
  );
}

export default WhoWeAre;
