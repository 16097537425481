import "./ContactUs.scss";

import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import { ReactComponent as Logo } from "../../assets/images/logoFullWhite.svg";
import contactusbg from "../../assets/images/contactusbg.jpg";
import linkedin from "../../assets/images/linkedIn.png";
import instagram from "../../assets/images/instagram.png";
import whatsapp from "../../assets/images/whatsapp.png";
import phone from "../../assets/images/phone.png";
import contactmen from "../../assets/images/contactmen.png";

const contactData = [
  {
    title: "Phone",
    value: "+91 991 569 7333",
    image: phone,
    link: "tel:+919915697333",
  },
  {
    title: "WhatsApp",
    value: "+91 991 569 7333",
    image: whatsapp,
    link: "https://wa.me/+919915697333",
  },
  {
    title: "Linkedin",
    value: "/Lancehawks",
    image: linkedin,
    link: "https://www.linkedin.com/company/lancehawks/",
  },
  {
    title: "Instagram",
    value: "/Lancehawks.Labs",
    image: instagram,
    link: "https://www.instagram.com/lancehawks.labs/",
  },
];

function ContactUs() {
  return (
    <div
      className="contactus"
      style={{ backgroundImage: `url(${contactusbg})` }}
    >
      <Link to={`/`} className="contactus-back">
        {" "}
        &lt; Back
      </Link>
      <div className="contactus-logo">
        <Link to={`/`}>
          <Logo />
        </Link>
      </div>
      <div className="contactus-contents">
        <div className="contactus-contents-left">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
            viewport={{ once: true }}
            className="contactus-contents-left-title"
          >
            Contact Us
          </motion.div>
          <motion.hr
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.4, delay: 0.3 }}
            viewport={{ once: true }}
            className="contactus-contents-left-line"
          />
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.3 }}
            viewport={{ once: true }}
            className="contactus-contents-left-desc"
          >
            Let's collaborate and create something incredible. We help design
            and build digital systems and handle the overall process including
            but not limited to design, develpment, deployment and maintenance of
            the systems. Reach out to us to know more.
          </motion.div>
        </div>
        <div className="contactus-contents-right">
          <div className="contactus-contents-right-cards">
            {contactData.map((ele, index) => (
              <ContactUsCard {...ele} key={index} index={index} />
            ))}
          </div>
          <div className="contactus-contents-right-email">
            <Link to={`mailto:contact@lancehawks.com`}>
              contact@lancehawks.com
            </Link>
          </div>
        </div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
        viewport={{ once: true }}
        motion
        className="contactus-bottom"
      >
        <img src={contactmen} alt="Icons8 icon of men at work" />
      </motion.div>
    </div>
  );
}

export default ContactUs;

function ContactUsCard({ image, title, value, link, index }) {
  const diversion = index % 2 === 0 ? 0 : 30;
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: diversion }}
      transition={{ duration: 0.4, delay: 0.4 + 0.3 * index }}
      viewport={{ once: true }}
      className="contactuscard"
    >
      <Link to={link}>
        <div className="contactuscard-image">
          <img src={image} alt="Phone Number of Lancehawks" />
        </div>
        <div className="contactuscard-title">{title}</div>
        <div className="contactuscard-value">{value}</div>
      </Link>
    </motion.div>
  );
}
