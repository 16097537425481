import "./Contact.scss";

import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import contactbg from "../../assets/images/contactbg.jpg";
import contactPerson from "../../assets/images/contact.png";
import Button from "../../components/Button/Button";

function Contact() {
  return (
    <motion.div
      initial={{ opacity: 0, y: 150 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
      id="Contact"
      className="contact"
      style={{ backgroundImage: `url(${contactbg})` }}
    >
      <div className="contact-left">
        <img
          src={contactPerson}
          alt="contact for building websites/apps. Pune. Icons8."
        />
      </div>
      <div className="contact-right">
        <div className="contact-right-text">
          So, ready to give your ideas a shape? Ready to transform your
          business?
        </div>
        <div className="contact-right-button">
          <Link to={`/contact`}>
            <Button text="Get In Touch" />
          </Link>
        </div>
      </div>
    </motion.div>
  );
}

export default Contact;
