import "./Button.scss";

import React from "react";

function Button({ text }) {
  return (
    <button
      // initial={{ y: 200 }}
      // whileInView={{ y: 0 }}
      // transition={{ duration: 0.4, delay: 0.3 }}
      className="button"
    >
      {text}
    </button>
  );
}

export default Button;
