import React, { useEffect, useRef } from "react";
import "./StackCard.scss";

const StackCard = ({ children, color }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const element = cardRef.current;
      const rect = element.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      // Detect when the current card passes 50% of the viewport
      if (rect.top < windowHeight / 1.2 && rect.bottom > windowHeight / 1.2) {
        // Select the previous card using previousElementSibling
        const previousElement = element.previousElementSibling;
        if (previousElement) {
          // Calculate how much the current card has scrolled past the 50% mark
          const progress = Math.min(
            1,
            (windowHeight / 1.2 - rect.top) / (windowHeight / 1.2)
          );

          // Use progress to shrink the previous card dynamically
          const shrinkFactor = Math.min(1, 1 - progress * 0.5); // Shrink down to 70%
          console.log("Srink: ", shrinkFactor);
          const rotateDegree = progress * 15; // Rotate up to 15 degrees
          const translateY = progress * 50; // Translate by 50px

          previousElement.style.transform = `scale(${shrinkFactor}) rotateX(${rotateDegree}deg) translateY(${translateY}px)`;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="scard" ref={cardRef} style={{ backgroundColor: color }}>
      {children}
    </div>
  );
};

export default StackCard;
