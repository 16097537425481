import "./Tag.scss";

import React from "react";

function Tag({ text }) {
  return (
    <div className="tag">
      <hr className="tag-line" />
      <div className="tag-text">{text}</div>
    </div>
  );
}

export default Tag;
