import "./Hero.scss";
import Header from "../../components/Header/Header";
import Button from "../../components/Button/Button";

import herobg from "../../assets/images/herobg.jpg";
import { ReactComponent as Cross } from "../../assets/images/cross.svg";

import { HashLink } from "react-router-hash-link";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

const { innerWidth: width } = window;

const MenuVariant = {
  initial: { x: width, scaleX: 0 },
  animate: { x: 0, scaleX: 1, transition: { duration: 0.4 } },
  exit: { x: width, transition: { duration: 0.4 } },
};

function Hero({ setHash }) {
  const [clicked, setClicked] = useState(false);

  const handleMenu = (value) => {
    setClicked(!clicked);
    setHash(value);
  };

  return (
    <div
      id="Home"
      className="hero"
      style={{ backgroundImage: `url(${herobg})` }}
    >
      <AnimatePresence>
        {clicked ? <Menu setHash={setHash} handleMenu={handleMenu} /> : ""}
      </AnimatePresence>

      <Header handleMenu={handleMenu} />

      <motion.div
        initial={{ opacity: 0, x: -10 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.4, delay: 0.3 }}
        viewport={{ once: true }}
        className="hero-border"
      ></motion.div>
      <div className="hero-content">
        <motion.div
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.4 }}
          viewport={{ once: true }}
          className="hero-content-subtext"
        >
          Building
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.4, delay: 0.3 }}
          viewport={{ once: true }}
          className="hero-content-text"
        >
          Incredible experiences <br /> Incredibly fast.
        </motion.div>
        <HashLink smooth to={`#Contact`} className="hero-content-button">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.4, delay: 0.8 }}
          >
            <Link to={`/contact`}>
              <Button text="Get In Touch" />
            </Link>
          </motion.div>
        </HashLink>
      </div>
    </div>
  );
}

export default Hero;

function Menu({ handleMenu, setHash }) {
  return (
    <motion.div
      className="menu"
      key="loader"
      variants={MenuVariant}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="menu-contents">
        <motion.div className="menu-contents-cross" onClick={handleMenu}>
          <Cross />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.4, delay: 0.3 }}
          viewport={{ once: true }}
          className="menu-contents-header"
        >
          Lancehawks
        </motion.div>
        <div className="menu-contents-links">
          <motion.HashLink
            initial={{ opacity: 0, x: 150 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
            viewport={{ once: true }}
            onClick={() => handleMenu("Home")}
            className="menu-contents-links-link"
          >
            Home
          </motion.HashLink>
          <motion.div
            initial={{ opacity: 0, x: 150 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: 0.4 }}
            viewport={{ once: true }}
            onClick={() => handleMenu("About")}
            className="menu-contents-links-link"
          >
            About Us
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 150 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3, delay: 0.6 }}
            viewport={{ once: true }}
            onClick={() => handleMenu("Services")}
            className="menu-contents-links-link"
            to="#Company"
          >
            Services
          </motion.div>
          <Link to={`/Contact`}>
            <motion.div
              initial={{ opacity: 0, x: 150 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: 0.8 }}
              viewport={{ once: true }}
              onClick={handleMenu}
              className="menu-contents-links-link"
              to="#Contact"
            >
              Contact Us
            </motion.div>
          </Link>
        </div>
        <div className="menu-contents-contact">
          <Link to={`mailto:contact@lancehawks.com`}>
            contact@lancehawks.com
          </Link>
        </div>
      </div>
    </motion.div>
  );
}
